'use client';
import { Heading, Text } from '@chakra-ui/react';
import Image from 'next/image';

import { ImageTransformationKeys } from '@/types/files/files.types';
import { CallToAction } from '@/types/home/home.types';
import { directusImageLoader } from '@/utils/loaders';

import CustomImage from '../custom-image/CustomImage';
import LinkCustom from '../link/LinkCustom';

import Styles from './DiscoverySection.module.scss';

type Props = {
  footer_call_to_action: CallToAction | null;
  footer_call_to_action_image: string | null;
  imageKeys?: ImageTransformationKeys;
  alt?: string;
  loading?: 'lazy' | 'eager';
};

const DiscoverySection = ({
  footer_call_to_action,
  footer_call_to_action_image,
  imageKeys,
  alt = 'card',
  loading = 'lazy',
}: Props) => {
  return (
    <>
      <div className={Styles.discoverySectionWrapper}>
        <div className={Styles.leftSection}>
          <div className={Styles.imageWrapper}>
            {!imageKeys ? (
              <Image
                src={
                  footer_call_to_action_image ? footer_call_to_action_image : ''
                }
                loader={directusImageLoader}
                alt="card"
                layout="fill"
                loading={loading}
              />
            ) : (
              <CustomImage
                imageKeys={imageKeys}
                imageSrc={
                  footer_call_to_action_image ? footer_call_to_action_image : ''
                }
                alt={alt}
                loading={loading}
              />
            )}
          </div>
        </div>
        <div className={Styles.rightSection}>
          <div className={Styles.headingElement}>
            {footer_call_to_action?.heading ? (
              <Heading
                as="p"
                className={Styles.titleLg}
                marginBottom={{ base: 4, md: 8 }}
              >
                {footer_call_to_action?.heading}
              </Heading>
            ) : null}
            {footer_call_to_action?.subheading ? (
              <Text className={Styles.titleText}>
                {footer_call_to_action?.subheading}
              </Text>
            ) : null}
            {footer_call_to_action?.url ? (
              <LinkCustom
                className="btnOutlineWhite"
                href={footer_call_to_action?.url || '#'}
              >
                {footer_call_to_action?.text}
              </LinkCustom>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default DiscoverySection;
